(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("sbInternalMsgBus"), require("sbRespBlockLib"), require("sbDataLayer"), require("mobxReact"), require("sbJsExtends"));
	else if(typeof define === 'function' && define.amd)
		define("sb-responsive-live-events-quick-navigation", ["React", "mobx", "sbInternalMsgBus", "sbRespBlockLib", "sbDataLayer", "mobxReact", "sbJsExtends"], factory);
	else if(typeof exports === 'object')
		exports["sb-responsive-live-events-quick-navigation"] = factory(require("React"), require("mobx"), require("sbInternalMsgBus"), require("sbRespBlockLib"), require("sbDataLayer"), require("mobxReact"), require("sbJsExtends"));
	else
		root["sb-responsive-live-events-quick-navigation"] = factory(root["React"], root["mobx"], root["sbInternalMsgBus"], root["sbRespBlockLib"], root["sbDataLayer"], root["mobxReact"], root["sbJsExtends"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__20__, __WEBPACK_EXTERNAL_MODULE__23__, __WEBPACK_EXTERNAL_MODULE__24__) {
return 